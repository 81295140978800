





























































import { API_SETTING_PUSH_RULE } from "@/configs/Apis";
import { MSG_INVALID_PAGE, MSG_MODIFY_SUCCESS } from "@/configs/Consts";
import { hasPermission, showError, showSuccess, showWarning } from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Vue } from "vue-property-decorator";
@Component
export default class PushRuleSetting extends Vue {
  settingItem: any = {};

  isIndeterminate = false;

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  handleBiboEnabledChange(checked: any) {
    this.settingItem.biboFields = checked
      ? this.settingItem.biboAllFields.map((biboField: any) => biboField.name)
      : [];
    this.isIndeterminate = false;
  }

  handleCheckedBiboFieldsChange(value: any) {
    const checkedCount = value.length;
    this.settingItem.biboEnabled =
      checkedCount === this.settingItem.biboAllFields.length;
    this.isIndeterminate =
      checkedCount > 0 && checkedCount < this.settingItem.biboAllFields.length;
  }

  created() {
    Ajax.queryData(API_SETTING_PUSH_RULE, {}, "GET")
      .then(data => {
        this.settingItem = JSON.parse(JSON.stringify(data || {}));
        this.settingItem.biboFields = this.settingItem.biboFields || [];
        this.handleCheckedBiboFieldsChange(this.settingItem.biboFields);
      })
      .catch(() => "");
  }

  submitData() {
    const form = this.$refs.settingForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      const params = JSON.parse(JSON.stringify(this.settingItem));
      params.biboEnabled = this.settingItem.biboFields.length > 0;
      Ajax.saveData(API_SETTING_PUSH_RULE, params, "PUT")
        .then(() => {
          showSuccess(MSG_MODIFY_SUCCESS);
        })
        .catch(({ message }) => showError(message));
    });
  }
}
